/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prefer-stateless-function */

import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import Helmet from 'react-helmet';

import Card from '../components/Card';

class CardTemplate extends React.Component {
  render() {
    const card = get(this.props, 'data.contentfulCard');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');

    return (
      <React.Fragment>
        <Helmet title={`${card.title} | ${siteTitle}`} />
        <Card {...card} />
      </React.Fragment>
    );
  }
}

export default CardTemplate;

export const pageQuery = graphql`
  query cardBySlug($slug: String!) {
    contentfulCard(slug: { eq: $slug }) {
      recipient
      title
      publishDate(formatString: "MMMM Do, YYYY")
      # heroImage {
      #   sizes(maxWidth: 1180, background: "rgb:000000") {
      #     ...GatsbyContentfulSizes_withWebp
      #   }
      # }
      introduction {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
