/* global tw */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { width as twWidth } from '../../tailwind';
import s1 from '../images/s1.svg';
import s2 from '../images/s2.svg';
import s3 from '../images/s3.svg';
import s4 from '../images/s4.svg';
import s5 from '../images/s5.svg';
import s6 from '../images/s6.svg';

const Wrapper = styled.div`
  ${tw('stroke-current absolute')};
  color: ${props => props.stroke};
  width: ${props => props.svgWidth};
  fill: ${props => props.fill};
  left: ${props => props.left};
  top: ${props => props.top};
`;

const iconToSrc = {
  triangle: s1,
  circle: s2,
  arrowUp: s3,
  upDown: s4,
  box: s5,
  hexa: s6,
};

const SVG = ({ stroke, width, icon, left, top, className }) => (
  <Wrapper {...{ left, top, className }}>
    <div style={{ transform: `scale(${width / 125.0})` }}>
      <img alt="flake" stroke={stroke} src={iconToSrc[icon]} />
    </div>
  </Wrapper>
);

export default SVG;

SVG.propTypes = {
  stroke: PropTypes.string,
  width: PropTypes.number,
  icon: PropTypes.oneOf(['triangle', 'circle', 'arrowUp', 'upDown', 'box', 'hexa']).isRequired,
  left: PropTypes.string,
  top: PropTypes.string,
  className: PropTypes.string,
};

SVG.defaultProps = {
  stroke: 'transparent',
  width: 8,
  left: '0%',
  top: '0%',
  className: 'fancy-icon',
};
